import styled from "styled-components";
import Colors from "../../constants/Colors";
import {deviceMax, deviceMin} from "../../../styles/device";


export const KayitPageWrapper = styled.div`
    position: relative;
    width: 100%;
    max-width: 808px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin: 0 auto;

    @media ${deviceMin.tablet} {
        display: none;
    }
`;

export const KayitPageContent = styled.div`
    position: relative;
    width: 100%;
    max-width: 431px;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: 20px;

    @media ${deviceMax.tablet} {
        padding: 0 10px;
    }
`;

export const TitleComp = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media ${deviceMax.tablet} {
        justify-content: center;
        margin-bottom: 0;
    }
`;

export const KayitPageTitle = styled.h1`
    position: relative;
    color: ${Colors.black};
    margin-bottom: 0;
    margin-top: 0;
    font-size: 18px;
    font-weight: 800;
    line-height: 48px;
    letter-spacing: 0.011em;
    text-align: left;
    text-transform: uppercase;

`;

export const KayitPageTitleText = styled.div`
    position: relative;
    color: ${Colors.black};
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;

    @media ${deviceMax.tablet} {
        padding: 18px 24px 14px;
        width: calc(100% + 48px);
        background-color: #fff;
        box-sizing: border-box;
        margin-left: -24px;
    }

    > p {
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        font-weight: 500;
        width: 100%;
    }
`;

export const KayitPageGender = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 4px;
    margin-bottom: 4px;
    gap: 4px;
`;

export const KayitPageGenderWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const KayitPageGenderTitle = styled.div`
    position: relative;
    font-weight: 800;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0;
    color: ${Colors.black};
    margin-bottom: 0;
    margin-top: 0;
`;

export const KayitPageGenderChoice = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;

    > div {
        padding: 0;
        background: unset;
        width: 100%;

        > div {
            flex-direction: column;
            width: 100%;

            > div {
                > fieldset {
                    flex-direction: row;
                    width: 100%;
                    gap: 5px;

                    > div {
                        padding: 11px 20px;
                        border: 1px solid #00000033;
                        border-radius: 10px;
                        background-color: ${Colors.white};
                        width: 100%;
                        
                        &:not(:first-child) {
                            margin-left: 0;
                        }
                        
                        &:last-child {
                            margin-bottom: 0;
                        }
                        
                        > div {
                            
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 11px;

                            &:hover {
                                input ~ .checkmark {
                                    background-color: ${Colors.white};

                                    &:after {
                                        border: none;
                                    }
                                }
                            }


                            > .checkmark {
                                width: 16px;
                                height: 16px;
                                background-color: ${Colors.white};
                                top: 4px;
                                left: 0;
                                border: 1px solid ${Colors.black};

                                &:after {
                                    border: none;
                                }
                                
                            }

                            > input {
                                top: 4px;
                                left: 0;
                                width: 16px;
                                height: 16px;

                                &:checked ~ .checkmark {
                                    border-color: ${Colors.brandColor};
                                    background-color: ${Colors.white};

                                    &:after {
                                        background-color: ${Colors.brandColor};
                                        width: 10px;
                                        height: 10px;
                                        border-radius: 50%;
                                        left: 2px;
                                        top: 2px;
                                    }
                                }
                            }

                            > label {
                                padding: 0;
                                margin-left: 27px;
                            }
                        }

                    }
                }
            }
        }
    }
`;

export const KayitPageForm = styled.form`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${Colors.white};
    padding: 11px 14px 39px 17px;
    border-radius: 6px;
    margin-bottom: 8px;

    > div {
        &:nth-child(2) {
            width: 100%;
            padding: 0;
            margin-bottom: 27px;

            @media ${deviceMax.tablet} {
                background-color: transparent;
                margin-bottom: 10px;
            }
        }
    }

    @media ${deviceMax.tablet} {
        background-color: transparent;
        padding: 0;
        margin-bottom: 4px;
    }
`;

export const StyledRememberMe = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
`;

export const StyledRememberMeCheckbox = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0;

    .lighting-button {
        background-color: transparent;
        border: none;
        outline: none;
        padding: 0;
        margin: 0;
        font-family: 'Manrope', sans-serif;
        font-weight: 800;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0;
        color: ${Colors.black};
        cursor: pointer;

        @media ${deviceMax.tablet} {
            font-size: 8px;
            line-height: 15px;
        }
    }

    > div {

        > label {

            > .checkmark {
                background-color: ${Colors.white};

                &:after {
                    display: none;
                }
            }
        }
    }
`;

export const KayitPageFormTriangle = styled.span`
    position: absolute;
    top: -30px;
    left: 20px;
    width: 0;
    height: 0;
    border-bottom: 30px solid ${Colors.white};
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;

    @media ${deviceMax.tablet} {
        display: none;
    }
`;

export const StyledLoginButton = styled.div`
    position: relative;

    > div {
        display: block;
        padding: 0;
        margin-bottom: 0;
    }
`;

export const StyledLoginLine = styled.div`
    position: relative;
    width: 100%;
    border: 1px solid ${Colors.lightBlackColor};
    margin-top: 24px;
    margin-bottom: 24px;

    @media ${deviceMax.tablet} {
        margin-top: 8px;
        margin-bottom: 8px;
    }
`;

export const StyledLoginFacebookLogin = styled.div`
    position: relative;

    > div {
        display: block;
        padding: 0;
        margin-bottom: 0;
    }
`;

export const StyledLoginNotPost = styled.div`
    position: relative;
    font-family: 'Manrope', sans-serif;
    font-weight: 800;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0;
    color: ${Colors.black};
    margin-top: 22px;
    margin-bottom: 30px;

    @media ${deviceMax.tablet} {
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        width: 80%;
        margin: 10px auto 15px;
    }
`;

export const StyledLoginImage = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
