import React, {ChangeEvent, useEffect, useState, KeyboardEvent} from "react";
import * as Styled from "./MobileKayitFormStyled";
import InputComp from "../buttons/inputComp/InputComp";
import FacebookLoginButton from "../generalButtons/FacebookLoginButton";
import SingleCheckboxAndText from "../checkboxComp/SingleCheckboxAndText";
import CheckboxComp from "../checkboxComp/CheckboxComp";
import {ApiClassicRegistration} from "../../api/RegistrationServies";
import SweetFailed from "../sweetAlert/failed";
import RegisterNow from "../generalButtons/RegisterNow";
import PopupComp from "../popup/PopupComp";
import LightingText from "../popup/LightingText";
import Image from "next/image";
import ElectronicMessage from "../popup/ElectronicMessage";
import ExpressConsentText from "../popup/ExpressConsentText";
import CustomAlert, {AlertType} from "../customAlert/CustomAlert";
import {isValidEmail} from "../../utils/ValidEmail";

const optionsGender = [
    {
        id: 'genderWoman',
        name: 'gender',
        title: 'Kadın',
        value: '1',
        defaultChecked: false
    },
    {
        id: 'genderMan',
        name: 'gender',
        title: 'Erkek',
        value: '0',
        defaultChecked: true
    }
];
const optionsLookingPerson = [
    {
        id: 'lookingPersonWoman',
        name: 'lookingPerson',
        title: 'Kadın',
        value: '1',
        defaultChecked: true
    },
    {
        id: 'lookingPersonMan',
        name: 'lookingPerson',
        title: 'Erkek',
        value: '0',
        defaultChecked: false
    }
];

const changeWriting = (e: ChangeEvent<HTMLInputElement>) => {
    // console.log(e.target.value);
}

const MobileKayitForm = () => {
    const [checked, setChecked] = React.useState(true);
    const [checkedElectronic, setCheckedElectronic] = React.useState(true);
    const [checkedExpress, setCheckedExpress] = React.useState(true);

    const [email, setEmail] = React.useState('');
    const [gender, setGender] = React.useState<'0' | '1'>('0');

    const [preventAction, setPreventAction] = React.useState(false);

    const [showPopup, setshowPopup] = React.useState(false);
    const [showPopupElectronic, setshowPopupElectronic] = React.useState(false);
    const [showPopupExpress, setshowPopupExpress] = React.useState(false);

    const [alert, setAlert] = useState<{ message: string; type: AlertType } | null>(null);

    useEffect(() => {
        if (showPopupElectronic || showPopup || showPopupExpress) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'revert-layer';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [showPopupElectronic, showPopup, showPopupExpress]);

    const handleAlertClose = () => {
        setAlert(null);
    };

    const alertValidate = () => {
        if (!email) {
            handleAlertClose();
            setAlert({
                message: '<span class="color-text-warning">Hata! </span><span class="text-thin">Lütfen geçerli bir e-posta adresi veya telefon numarası giriniz.</span>',
                type: 'fail'
            });
        } else {
            startRegistration();
        }
    };

    const handleSubmit = (event :any) => {
        event.preventDefault(); // This stops the form from submitting traditionally
        alertValidate(); // Continue with your custom validation and AJAX submission
    };

    const startRegistration = () => {

        if (preventAction) {
            return;
        }

        if (checked && checkedElectronic && checkedExpress) {
            setPreventAction(true);
            ApiClassicRegistration(
                email,
                (gender === '0') ? 0 : 1
            ).then(() => {
                setPreventAction(false);
            }).catch((error) => {
                setAlert({message: error, type: 'fail'});
                setPreventAction(false);
            });
        } else {
            setAlert({
                message: '<span class="color-text-warning">Hata! </span><span class="text-thin">Kayıt için</span> <span>Kişisel Aydınlatma Metni</span> <span class="text-thin">ve</span> <span>Ticari Elektronik ileti</span> <span class="text-thin">iznini kabul etmeniz gerekiyor.</span>',
                type: 'fail'
            });
        }

    };


    return (
        <React.Fragment>
            <Styled.KayitPageTitleText>
                Şimdi kayıt olun, uygun üyeleri ücretsiz görün.
                <p>
                    Ücretsiz kişilik testimizden yararlanın.
                </p>
                <p>
                    Profiliniz gizli kalsın.
                </p>
            </Styled.KayitPageTitleText>
            <Styled.KayitPageWrapper>
                <Styled.KayitPageContent>
                    <Styled.TitleComp>
                        {/*<Styled.KayitPageTitle>*/}
                        {/*    Ücretsiz Üye Ol*/}
                        {/*</Styled.KayitPageTitle>*/}
                    </Styled.TitleComp>
                    <Styled.KayitPageGender>
                        <Styled.KayitPageGenderWrapper>
                            <Styled.KayitPageGenderTitle>
                                Cinsiyetiniz:
                            </Styled.KayitPageGenderTitle>
                            <Styled.KayitPageGenderChoice>
                                <CheckboxComp
                                    options={optionsGender}
                                    selectedValue={gender}
                                    onChange={(newValue: '0' | '1') => {
                                        setGender(newValue);
                                    }}
                                    mobileRegister={true}
                                />
                            </Styled.KayitPageGenderChoice>
                        </Styled.KayitPageGenderWrapper>
                        <Styled.KayitPageGenderWrapper>
                            <Styled.KayitPageGenderTitle>
                                Aradığınız Kişi:
                            </Styled.KayitPageGenderTitle>
                            <Styled.KayitPageGenderChoice>
                                <CheckboxComp
                                    options={optionsLookingPerson}
                                    selectedValue={(gender === '1') ? '0' : '1'}
                                    onChange={(newValue: '0' | '1') => {
                                        let newGender: '0' | '1' = (newValue === '0') ? '1' : '0';
                                        setGender(newGender);
                                    }}
                                    mobileRegister={true}
                                />
                            </Styled.KayitPageGenderChoice>
                        </Styled.KayitPageGenderWrapper>
                    </Styled.KayitPageGender>
                    <Styled.KayitPageForm action={'#'} target={'_top'} onSubmit={handleSubmit}>
                        <Styled.KayitPageFormTriangle/>
                        <InputComp title={'E-posta veya Telefon Numarası:'}
                                   placeholder={'E-posta veya telefon numaranızı girin'}
                                   changeWriting={(event: ChangeEvent<HTMLInputElement>) => {
                                       setEmail(event.target.value);
                                   }}
                                   value={email}
                                   onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                                       if (event.key === 'Enter') {
                                           alertValidate();
                                       }
                                   }}
                                   handleAlertClose={handleAlertClose}
                        />
                    </Styled.KayitPageForm>
                    <Styled.StyledRememberMe>
                        <Styled.StyledRememberMeCheckbox>
                            <SingleCheckboxAndText titleColor={false} checked={checked} mobile={true}
                                                   onChange={() => {
                                                       setChecked(!checked);
                                                       handleAlertClose();
                                                   }}>
                                Kişisel verilerimin işlenmesine ilişkin <button className={'lighting-button'}
                                                                                onClick={() => {
                                                                                    setshowPopup(!showPopup);
                                                                                    handleAlertClose();
                                                                                }}>Aydınlatma
                                Metni</button>’ni okudum.
                            </SingleCheckboxAndText>
                            <SingleCheckboxAndText titleColor={false} checked={checkedExpress} mobile={true}
                                                   onChange={() => {
                                                       setCheckedExpress(!checkedExpress);
                                                       handleAlertClose();
                                                   }}>
                                Özel nitelikli kişilik testi bilgilerimin işlenmesini <button
                                className={'lighting-button'}
                                onClick={() => {
                                    setshowPopupExpress(!showPopupExpress);
                                    handleAlertClose();
                                }}>Açık Rıza
                                Metni</button> kapsamında işlenmesini onay veriyorum.
                            </SingleCheckboxAndText>
                            <SingleCheckboxAndText className={'check-wrapper'} titleColor={false} mobile={true}
                                                   checked={checkedElectronic}
                                                   onChange={() => {
                                                       setCheckedElectronic(!checkedElectronic);
                                                       handleAlertClose();
                                                   }}>
                                Ürün veya hizmetlere ilişkin kampanya ve duyurulardan haberdar olmak için
                                tarafıma <button className={'lighting-button'}
                                                 onClick={() => {
                                                     setshowPopupElectronic(!showPopupElectronic);
                                                     handleAlertClose();
                                                 }}> Açık Rıza
                                Metni</button> kapsamında ticari elektronik ileti gönderilmesini istiyorum.
                            </SingleCheckboxAndText>
                        </Styled.StyledRememberMeCheckbox>
                    </Styled.StyledRememberMe>
                    {alert && <CustomAlert message={alert.message} type={alert.type} margin={false}/>}
                    <Styled.StyledLoginButton>
                        <RegisterNow registerFunction={() => {
                            alertValidate();
                        }} isNonNavigationButton={true}/>
                    </Styled.StyledLoginButton>
                    <Styled.StyledLoginLine/>
                    <Styled.StyledLoginFacebookLogin>
                        <FacebookLoginButton mobileVisible={false}/>
                    </Styled.StyledLoginFacebookLogin>
                    <Styled.StyledLoginNotPost>
                        Facebook duvarında hiçbir şey paylaşmayacağız.
                    </Styled.StyledLoginNotPost>
                    <Styled.StyledLoginImage>
                        <Image src={'/next/assets/images/digicert.png'} alt={'hero_area'} loading={'lazy'} width={90}
                               height={42}/>
                    </Styled.StyledLoginImage>
                    {showPopup ? (
                        <PopupComp onClose={() => setshowPopup(!showPopup)}>
                            <LightingText showPopup/>
                        </PopupComp>
                    ) : null}
                    {showPopupElectronic ? (
                        <PopupComp onClose={() => setshowPopupElectronic(!showPopupElectronic)}>
                            <ElectronicMessage showPopup/>
                        </PopupComp>
                    ) : null}
                    {showPopupExpress ? (
                        <PopupComp onClose={() => setshowPopupExpress(!showPopupExpress)}>
                            <ExpressConsentText showPopup/>
                        </PopupComp>
                    ) : null}
                </Styled.KayitPageContent>
            </Styled.KayitPageWrapper>
        </React.Fragment>
    )

}

export default MobileKayitForm;
